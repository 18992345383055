import { GetProjectMember, IGetProjectMember } from "../members/GetProjectMember";
import { GetVmInstance, IGetVmInstance } from "../vm-instances/GetVmInstance";
import { GetVmTemplateVersion, IGetVmTemplateVersion } from "../vm-template-versions/GetVmTemplateVersion";
import { GetProjectDeploymentTask, IGetProjectDeploymentTask } from "./GetProjectDeploymentTask";
import { IGetBaseRoom } from "src/app/components/core/base-room/models/IGetBaseRoom";
import { RunState } from "src/app/components/core/base-room/enumerations/RunState";
import { DeploymentState } from "src/app/components/core/base-room/enumerations/DeploymentState";

export interface IGetProject extends IGetBaseRoom {
  requiresRedeployment: boolean,
  costCenter: string,
  location: string,
  vmTemplateVersion: IGetVmTemplateVersion,
  vmSize: string,
  members: IGetProjectMember[],
  currentTask?: IGetProjectDeploymentTask,
  vmInstance?: IGetVmInstance,
}

export class GetProject implements IGetProject {
  uid!: string;
  title!: string;
  description?: string | undefined;
  deploymentState!: DeploymentState;
  runState = RunState.Pending;
  requiresRedeployment!: boolean;
  costCenter!: string;
  location!: string;
  vmTemplateVersion!: GetVmTemplateVersion;
  vmSize!: string;
  members!: GetProjectMember[];
  guacamoleFqdn?: string | undefined;
  currentTask?: GetProjectDeploymentTask;
  vmInstance?: GetVmInstance;



  constructor(simpleProject: IGetProject) {
    this.uid                  = simpleProject.uid;
    this.title                = simpleProject.title;
    this.description          = simpleProject.description;
    this.deploymentState      = simpleProject.deploymentState;
    this.requiresRedeployment = simpleProject.requiresRedeployment;
    this.costCenter           = simpleProject.costCenter;
    this.location             = simpleProject.location;
    this.vmTemplateVersion    = new GetVmTemplateVersion(simpleProject.vmTemplateVersion);
    this.vmSize               = simpleProject.vmSize;
    this.members              = simpleProject.members.map(p => new GetProjectMember(p));
    this.guacamoleFqdn        = simpleProject.guacamoleFqdn;
    this.currentTask          = simpleProject.currentTask ? new GetProjectDeploymentTask(simpleProject.currentTask) : undefined;
    this.vmInstance           = simpleProject.vmInstance ? new GetVmInstance(simpleProject.vmInstance) : undefined;
  }

  public get hasActiveTask(): boolean {
    return this.currentTask?.isActive === true;
  }
}