<div class="toast-sidebar">
</div>

<div class="toast-icon">
    <tec-icon *ngIf="!options.payload || options.payload?.status === 'Queued'" key="Info"></tec-icon>
    <tec-icon *ngIf="options.payload?.status === 'InProgress'" key="Info"></tec-icon>
    <tec-icon *ngIf="options.payload?.status === 'Cancelled'" key="Info"></tec-icon>
    <tec-icon *ngIf="options.payload?.status === 'Error'" key="Warning-Alt"></tec-icon>
    <tec-icon *ngIf="options.payload?.status === 'Success'" key="Check"></tec-icon>
</div>

<div class="toast-content">
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
        {{ options.payload ? ( title | translate : options.payload ) : ( title | translate ) }}
    </div>

    <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass"
        [innerHTML]="message">
    </div>

    <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass"
        [attr.aria-label]="message">
        {{ options.payload ? ( message | translate : options.payload ) : ( message | translate ) }}
    </div>

</div>