import { User } from "@auth0/auth0-spa-js";
import { VmTemplateVersionState } from "src/app/enumerations/VmTemplateVersionState";
import { IGetConnectionTemplate } from "../connection-templates/GetConnectionTemplate";
import { IGetVmTemplateChild } from "../vm-templates/GetVmTemplateChild";
import { GetVmTemplateVersionTask, IGetVmTemplateVersionTask } from "./GetVmTemplateVersionTask";

export interface IGetVmTemplateVersion {
  vmTemplateUid: string,
  version: number,
  description?: string,
  state: VmTemplateVersionState,
  currentTask?: IGetVmTemplateVersionTask;
  isSpecialized: boolean,
  specializedComputername?: string,
  specializedUsername?: string,
  specializedPassword?: string,
  connections: IGetConnectionTemplate[],
  creationDate: Date,
  creator: User,
  vmTemplate: IGetVmTemplateChild
}

export class GetVmTemplateVersion implements IGetVmTemplateVersion {

  vmTemplateUid: string;
  version: number;
  description?: string | undefined;
  state: VmTemplateVersionState;
  currentTask?: GetVmTemplateVersionTask;
  isSpecialized: boolean;
  specializedComputername?: string | undefined;
  specializedUsername?: string | undefined;
  specializedPassword?: string | undefined;
  connections: IGetConnectionTemplate[];
  creationDate: Date;
  creator: User;
  vmTemplate: IGetVmTemplateChild;
  
  constructor(simpleVmTemplateVersion: IGetVmTemplateVersion) {
    this.vmTemplateUid           = simpleVmTemplateVersion.vmTemplateUid;
    this.version                 = simpleVmTemplateVersion.version;
    this.description             = simpleVmTemplateVersion.description;
    this.state                   = simpleVmTemplateVersion.state;
    this.currentTask             = simpleVmTemplateVersion.currentTask ? new GetVmTemplateVersionTask(simpleVmTemplateVersion.currentTask) : undefined;
    this.isSpecialized           = simpleVmTemplateVersion.isSpecialized;
    this.specializedComputername = simpleVmTemplateVersion.specializedComputername;
    this.specializedUsername     = simpleVmTemplateVersion.specializedUsername;
    this.specializedPassword     = simpleVmTemplateVersion.specializedPassword;
    this.connections             = simpleVmTemplateVersion.connections;
    this.creationDate            = simpleVmTemplateVersion.creationDate;
    this.creator                 = simpleVmTemplateVersion.creator;
    this.vmTemplate              = simpleVmTemplateVersion.vmTemplate;
  }
  

  public get hasActiveTask(): boolean {
    return this.currentTask?.isActive === true;
  }
}