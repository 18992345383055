import { VmInstanceOperation } from "src/app/enumerations/VmInstanceOperation";
import { GetBackgroundTask, IGetBackgroundTask } from "../GetBackgroundTask";

export interface IGetVmInstanceTask extends IGetBackgroundTask {
  vmInstanceUid: string;
  operation: VmInstanceOperation;
}

export class GetVmInstanceTask extends GetBackgroundTask implements IGetVmInstanceTask {
  
  vmInstanceUid: string;
  operation: VmInstanceOperation;


  constructor(simpleTask: IGetVmInstanceTask) {
    super(simpleTask);
    this.vmInstanceUid = simpleTask.vmInstanceUid;
    this.operation     = simpleTask.operation;
  }
}
