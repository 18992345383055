import { DeploymentOperation } from "src/app/enumerations/DeploymentOperation";
import { GetBackgroundTask, IGetBackgroundTask } from "../GetBackgroundTask";

export interface IGetRoomDeploymentTask extends IGetBackgroundTask {
  roomUid: string;
  operation: DeploymentOperation;
}

export class GetRoomDeploymentTask extends GetBackgroundTask implements IGetRoomDeploymentTask {
  
  roomUid: string;
  operation: DeploymentOperation;


  constructor(simpleTask: IGetRoomDeploymentTask) {
    super(simpleTask);
    this.roomUid   = simpleTask.roomUid;
    this.operation = simpleTask.operation;
  }
}