import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { hasSomeRole } from "src/app/core/guards/permissions/role-utils";
import { GlobalRole } from "src/app/enumerations/GlobalRole";

@Directive({
  selector: '[cloudlabHasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {

  @Input() cloudlabHasRole!: string[];

  stop$ = new Subject<any>();

  isVisible = false;


  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authService: AuthService
  ) { }


  ngOnInit(): void {
    this.authService.user$.pipe(
      takeUntil(this.stop$)
    ).subscribe(user => {

      let requiredRoles = this.cloudlabHasRole.map(r => <GlobalRole>r);
      if (user && hasSomeRole(user, requiredRoles)) {
        if (!this.isVisible) {
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      }

      else {
        this.isVisible = false;
        this.viewContainerRef.clear();
      }

    });
  }

  ngOnDestroy(): void {
    this.stop$.next(null);
  }
}