<div id="tecan-logo" [routerLink]="['']" class="clickable">
  <svg width="167" height="29" viewBox="0 0 167 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Tecan</title>
    <path
      d="M14.1238 -3.05176e-05C6.32422 -3.05176e-05 0 6.36925 0 14.2226C0 22.0775 6.32422 28.4453 14.1238 28.4453C21.9278 28.4453 28.252 22.0775 28.252 14.2226C28.252 6.36925 21.9278 -3.05176e-05 14.1238 -3.05176e-05Z"
      fill="#E64414" />
    <path
      d="M14.1238 26.9086C11.385 26.9086 9.16321 24.6714 9.16321 21.9108C9.16321 19.1517 11.385 16.9131 14.1238 16.9131C16.867 16.9131 19.0903 19.1517 19.0903 21.9108C19.0903 24.6714 16.867 26.9086 14.1238 26.9086Z"
      fill="white" />
    <path
      d="M51.8063 1.48412L31.4207 1.48412L31.1462 7.66478H32.1453L32.157 7.50978C32.3791 4.73309 33.673 3.44052 36.2245 3.44052H39.7678V21.8304C39.7678 25.0151 39.7678 25.7725 36.4118 25.7725H36.2448V26.7858L47.1405 26.7858V25.7725H46.9749C43.6204 25.7725 43.6204 25.0151 43.6204 21.8304V3.44052L47.1623 3.44052C49.7152 3.44052 51.0076 4.73309 51.2298 7.50978L51.2429 7.66478H52.242L51.9646 1.48412H51.8063Z"
      fill="#272727" />
    <path
      d="M74.0158 1.48412L54.8239 1.48412V2.49741H54.9895C58.344 2.49741 58.344 3.25483 58.344 6.44093V21.8304C58.344 25.0151 58.344 25.7725 54.9895 25.7725H54.8239V26.7858L74.3237 26.7858L76.5354 20.1913H75.4956L75.4477 20.2732C73.5032 23.626 72.3197 25.0926 69.1482 25.0926H64.0525C62.5262 25.0926 62.1981 24.7884 62.1981 23.376V14.4157H67.0149C69.9309 14.4157 70.898 14.6906 71.1943 17.9586L71.2059 18.1121H72.1963V9.06701H71.203L71.1928 9.22346C70.9779 12.4344 69.9367 12.7225 67.0149 12.7225H62.1981V3.17733H68.3625C71.9334 3.17733 72.7641 3.51802 73.4422 7.15009L73.4669 7.28753H74.5328L74.1727 1.48412H74.0158Z"
      fill="#272727" />
    <path
      d="M99.6888 0.918243L99.6612 1.05569C99.5494 1.64934 99.1515 2.65386 98.363 2.65386C98.0595 2.65386 97.7095 2.47693 97.4263 2.3351L97.3523 2.29416C95.4485 1.35544 93.8467 0.957722 91.9967 0.957722C84.6632 0.957722 79.1348 6.83425 79.1348 14.6262C79.1348 22 84.2595 27.3516 91.3214 27.3516C96.0889 27.3516 99.2982 25.3484 101.732 20.8537L101.803 20.7177L100.98 20.1664L100.887 20.2995C98.6767 23.5265 96.6364 25.3952 92.7445 25.3952C90.6142 25.3952 83.6583 24.6173 83.6583 14.5867C83.6583 7.06381 86.8313 2.57344 92.1462 2.57344C96.2849 2.57344 99.0281 4.94072 100.302 9.61678L100.335 9.73668H101.199L100.617 0.918243H100.461H99.6888Z"
      fill="#272727" />
    <path
      d="M109.627 16.7215C109.812 16.2931 113.403 7.89434 113.682 7.24366C113.956 7.89872 117.487 16.2961 117.665 16.7215H109.627ZM127.886 25.7725C125.369 25.6701 124.624 23.8555 123.763 21.7485L123.612 21.3888L115.141 0.918251H114.247L105.29 22.0658C104.193 24.6422 103.467 25.5897 101.569 25.7725L101.42 25.7886V26.7858H109.54V25.7871L109.389 25.7725C107.84 25.6482 106.656 25.4566 106.656 24.281C106.656 23.5543 107.266 22.2515 107.465 21.8246C107.467 21.8202 108.686 18.9689 108.923 18.4118H118.296C118.375 18.6019 119.598 21.4429 119.598 21.4429L119.767 21.8494C120.07 22.5791 120.485 23.5821 120.485 24.243C120.485 25.3031 119.88 25.6877 118.094 25.7725L117.938 25.7812V26.7858H128.044V25.7798L127.886 25.7725Z"
      fill="#272727" />
    <path
      d="M155.208 1.48414H146.389V2.49744H146.553C149.914 2.49744 149.914 3.25485 149.914 6.44095V19.9457C149.482 19.4178 134.985 1.48414 134.985 1.48414H127.999V2.49744H128.165C130.504 2.49744 131.526 3.26947 132.904 4.99339V21.8304C132.904 25.0151 132.904 25.7725 129.55 25.7725H129.386V26.7858H138.367V25.7725H138.203C134.847 25.7725 134.847 25.0151 134.847 21.8304V7.34751C135.274 7.87389 150.933 27.2025 150.933 27.2025H151.854V6.44095C151.854 3.25485 151.854 2.49744 155.208 2.49744H155.374V1.48414H155.208Z"
      fill="#272727" />
    <path
      d="M166.222 22.0322C166.222 24.7914 164.001 27.0285 161.26 27.0285C158.518 27.0285 156.296 24.7914 156.296 22.0322C156.296 19.2731 158.518 17.033 161.26 17.033C164.001 17.033 166.222 19.2731 166.222 22.0322Z"
      fill="#E64414" />
  </svg>
</div>

<span class="spacer"></span>

<cloudlab-user-card *ngIf="user" [user]="user" [showEmail]="false"></cloudlab-user-card>

<div class="cloudlab-current-user" *ngIf="user">

  <span id="cloudlab-current-user-logout">
    <tec-icon key="../../../assets/icons/Login" [boxSize]=24 color="#272727" title="Logout" (click)="logout()">
    </tec-icon>
  </span>
</div>