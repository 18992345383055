<cloudlab-card
  [title]="title"
  [subline]="subline">

  <ng-container *ngTemplateOutlet="content">
  </ng-container>
  
  <div class="cloudlab-overlay-button-container">
    <tec-button *ngFor="let button of buttons" type="button" (click)="button.callback()" [appearance]="button.appearance">
      {{ button.label | translate }}
    </tec-button>
  </div>
</cloudlab-card>