import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, TemplateRef, Type, ViewContainerRef } from "@angular/core";
import { PopoverContainerComponent } from "./container/popover-container.component";

@Directive({
  selector: '[cloudlabPopover]'
})
export class PopoverDirective implements OnDestroy {

  @Input('cloudlabPopover')
  contentTemplate!: TemplateRef<any>;

  @Input('cloudlabPopoverIf')
  showPopover: boolean = true;

  containerComponent?: ComponentRef<PopoverContainerComponent>;


  constructor(
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef
    ) {

  }


  ngOnDestroy(): void {   
    if (this.containerComponent) {
      this.containerComponent.destroy();
      this.containerComponent = undefined;
   }
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.showPopover && !this.containerComponent) {
      let x = this.elementRef.nativeElement.getBoundingClientRect().left + this.elementRef.nativeElement.offsetWidth / 2; // Get the middle of the element
      let y = this.elementRef.nativeElement.getBoundingClientRect().top + this.elementRef.nativeElement.offsetHeight + 6; // Get the bottom of the element, plus a little extra
      this.createPopover(x, y);
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (this.showPopover && this.containerComponent) {
      this.containerComponent.destroy();
      this.containerComponent = undefined;
     }
  }


  private createPopover(x: number, y: number) {
    this.containerComponent = this.viewContainerRef.createComponent(PopoverContainerComponent);
    this.containerComponent.instance.content = this.contentTemplate;
    this.containerComponent.instance.left = x;
    this.containerComponent.instance.top = y;
  }
}