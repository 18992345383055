import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { combineLatest, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    return combineLatest([
      this.authService.isAuthenticated$,
      this.authService.user$
    ])
    .pipe(
      map(([isAuthenticated, user]) => {

        // check if the user is logged in
        if (!isAuthenticated) {
          this.router.navigate(
            ['/login'], 
            { 
              queryParams: { 
                  redirect_uri: state.url,
                  reason: 'unauthenticated'
              }
            }
          );
          return false;
        }

        // check if the user has any of the roles
        if (!user /* temporarily disabled role checks as instructed by Beat Amrein on 2022-08-22 >> || !hasSomeRole(user, [GlobalRole.Admin, GlobalRole.RoomAdmin, GlobalRole.TemplateAdmin, GlobalRole.Host]) */) {

          const loginUrl = window.location.origin + this.router.createUrlTree(
            ['/login'],
            {
              queryParams: { 
                redirect_uri: state.url,
                reason: 'unauthorized'
              }
            }
          );
          this.authService.logout({ logoutParams: { returnTo: loginUrl }});
          return false;
        }

        return true;
      })
    );
  }
}