import { TaskStatus } from "src/app/enumerations/TaskStatus";

export interface IGetBackgroundTask {
  isActive: boolean;
  isDone: boolean;
  uid: string;
  status: TaskStatus;
  queuedAt: string;
  message?: string;
  executionStart?: string;
  executionEnd?: string;
  errorCode?: string;
}

export class GetBackgroundTask implements IGetBackgroundTask {

  uid: string;
  status: TaskStatus;
  queuedAt: string;
  message?: string | undefined;
  executionStart?: string | undefined;
  executionEnd?: string | undefined;
  errorCode?: string;


  constructor(simpleTask: IGetBackgroundTask) {
    this.uid            = simpleTask.uid;
    this.status         = simpleTask.status;
    this.queuedAt       = simpleTask.queuedAt;
    this.message        = simpleTask.message;
    this.executionStart = simpleTask.executionStart; 
    this.executionEnd   = simpleTask.executionEnd;
    this.errorCode      = simpleTask.errorCode;
  }


  public get isActive(): boolean {
    return this.status === TaskStatus.Queued || this.status === TaskStatus.InProgress;
  }

  public get isDone(): boolean {
    return this.status === TaskStatus.Cancelled || this.status === TaskStatus.Error || this.status === TaskStatus.Success;
  }
}