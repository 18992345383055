import { HttpClient, HttpResponse } from "@angular/common/http";
import { AuthService } from '@auth0/auth0-angular';
import { Injectable } from "@angular/core";
import { tap, map } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { Health, IHealth } from "src/app/models/health/GetHealth";
@Injectable({
  providedIn: "root"
})
export class FeatureFlagsService {
  config: Health = new Health("",{});

  constructor(
    private http: HttpClient,     
    public auth: AuthService) {
  }

  /**
   * We convert it to promise so that this function can
   * be called by the APP_INITIALIZER
   */
  async loadConfig() {
    return this.http
    .get<IHealth>(`${environment.baseApiUrl}/health`)
    .pipe(tap(data => (this.config = data)))
    .toPromise();
  }

  isFeatureEnabled(key: string) {
    if (this.config && this.config.featureSets[key] !== undefined) {
      return this.config.featureSets[key];
    }
    else
      return false;
  }
}
