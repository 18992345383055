import { CloudLabOverlayService } from "./overlay.service";
import { ComponentRef } from "@angular/core";

export class OverlayRef {

  constructor(
    private overlayService: CloudLabOverlayService,
    private overlayComponent: ComponentRef<any>
    )
  {

  }

  close(): void {
    this.overlayService.close();
  }
}