import { TecDateRangePickerValue } from "@tecan/ui/material/date-range-picker";

export interface IDeploymentSchedule {
  start: Date;
  end: Date;

  toTecDateRangeValue(): TecDateRangePickerValue;
}

export class DeploymentSchedule implements IDeploymentSchedule {

  constructor(
    public start: Date,
    public end: Date
  ) { }

  
  public static fromTecDateRangeValue(dateRange: TecDateRangePickerValue) {
    let start = new Date(dateRange.start);
    let end = new Date(dateRange.end);

    return new DeploymentSchedule(
      new Date(start.getFullYear(), start.getMonth(), start.getDate(), 6),
      new Date(end.getFullYear(), end.getMonth(), end.getDate(), 22)
    );
  }

  public toTecDateRangeValue() {
    return {
      start: DeploymentSchedule.serialize(this.start),
      end: DeploymentSchedule.serialize(this.end)
    }
  }


  private static serialize(date: Date) {
    return [
      date.getFullYear(),
      DeploymentSchedule.twoDigits(date.getMonth() + 1),
      DeploymentSchedule.twoDigits(date.getDate())
    ].join('-') + "T00:00:00Z";
  }

  private static twoDigits(n: number) {
    return String(n).padStart(2, '0');
  }
}