import { ProjectRole } from "src/app/enumerations/ProjectRole";
import { IGetUser } from "../users/GetUser";

export interface IGetProjectMember {
    user: IGetUser;
    role: ProjectRole;
}

export class GetProjectMember implements IGetProjectMember {
    user: IGetUser;
    role: ProjectRole;


    constructor(simpleMember: IGetProjectMember) {
        this.user       = simpleMember.user;
        this.role       = simpleMember.role;
    }
}