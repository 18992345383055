import { CommonModule } from '@angular/common';
import { DurationPipe } from './pipes/duration.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HasRoleDirective } from './permissions/has-role.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgModule } from '@angular/core';
import { PopoverContainerComponent } from './popover/container/popover-container.component';
import { PopoverDirective } from './popover/popover.directive';
import { RouterModule } from '@angular/router';
import { TecDateRangePickerModule } from '@tecan/ui/material/date-range-picker';
import { TecIconModule } from '@tecan/ui/icon';
import { TranslateDirective } from './language/translate.directive';
import { TruncatePipe } from './pipes/truncate.pipe';

const COMPONENTS: any[] = [
  DurationPipe,
  HasRoleDirective,
  PopoverContainerComponent,
  PopoverDirective,
  TranslateDirective,
  TruncatePipe
];

const OTHER_MODULES: any[] = [
  FormsModule,
  MatAutocompleteModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatToolbarModule,
  ReactiveFormsModule,
  TecDateRangePickerModule
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [CommonModule, RouterModule, TecIconModule, OTHER_MODULES],
  exports: [CommonModule, OTHER_MODULES, COMPONENTS],
  providers: []
})
export class SharedComponentsModule { }