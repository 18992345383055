import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { CloudLabToastComponent } from './components/shared/toast/toast.component';
import { CloudLabToastModule } from './components/shared/toast/toast.module';
import { environment } from 'src/environments/environment';
import { FeatureFlagsService } from "./components/shared/feature/feature-flags.service";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutComponentsModule } from './components/layout/layouts-components.module';
import { MainComponent } from './components/layout/main/main.component';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { missingTranslationHandler, translatePartialLoader } from './config/translation.config';
import { SharedComponentsModule as SharedComponentsModule } from './components/shared/shared-components.module';
import { SignalRService } from './components/shared/signalr/service/signalr.service';
import { ToastrModule } from 'ngx-toastr';

const featureFactory = (featureFlagsService: FeatureFlagsService) => () =>
  featureFlagsService.loadConfig();

@NgModule({
  declarations: [
    MainComponent
  ],
  imports: [
    CloudLabToastModule,
    BrowserModule,
    LayoutComponentsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedComponentsModule,
    
    // Auth0 authentication
    // Handles token management for authentication against the backend.
    AuthModule.forRoot(
      {
        domain: environment.auth0.domain,
        clientId: environment.auth0.clientId,
        authorizationParams: {
          redirect_uri: window.location.origin,
          audience: environment.auth0.audience
        },

        // configure the AuthHttpIntercepter
        httpInterceptor: {
          allowedList: [
            {
              uriMatcher: (uri) => {
                // health api does not require authentication; needed for featuresets detection
                if (uri === `${environment.baseApiUrl}/health` || uri.indexOf(`/assets/`) !== -1)
                  return false;
                else
                  return true;
              },
              tokenOptions: {
                authorizationParams: {
                  audience: environment.auth0.audience
                }
              }
            }
          ]
        }
      }
    ),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translatePartialLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: missingTranslationHandler,
      },
    }),
    ToastrModule.forRoot({
      toastComponent: CloudLabToastComponent,
      positionClass: 'toast-top-right',
      timeOut: 15000
    })
  ],
  providers: [
    Title,
    {
      provide: LOCALE_ID,
      useValue: 'en'
    },
    // Auth0 implementation of the HttpInterceptor
    // Handles token based authentication using the AuthModule configured above.
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    SignalRService,
    
    {
      provide: APP_INITIALIZER,
      useFactory: featureFactory,
      deps: [FeatureFlagsService],
      multi: true
    }
  ],
  bootstrap: [MainComponent]
})
export class AppModule {
  constructor(translateService: TranslateService) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

}