import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGetRunState } from 'src/app/models/GetRunState';
import { IGetProjectMember } from 'src/app/models/members/GetProjectMember';
import { IPostProjectMember } from 'src/app/models/members/PostProjectMember';
import { GetProject, IGetProject } from 'src/app/models/projects/GetProject';
import { GetProjectDeploymentTask, IGetProjectDeploymentTask } from 'src/app/models/projects/GetProjectDeploymentTask';
import { IPatchProject } from 'src/app/models/projects/PatchProject';
import { IPostProject } from 'src/app/models/projects/PostProject';
import * as env from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  protected resourceUrl = env.environment.baseApiUrl + '/project';

  constructor(protected http: HttpClient) { }

  query(): Observable<GetProject[]> {
    return this.http.get<IGetProject[]>(this.resourceUrl, { observe: 'response' })
      .pipe(
        map((res: HttpResponse<IGetProject[]>) => res.body!.map(r => new GetProject(r)))
      );
  }

  find(projectUid: string): Observable<GetProject> {
    return this.http.get<IGetProject>(`${this.resourceUrl}/${projectUid}`, { observe: 'response' })
      .pipe(
        map((res: HttpResponse<IGetProject>) => new GetProject(res.body!))
      );
  }

  getRunState(projectUid: string): Observable<HttpResponse<IGetRunState>> {
    return this.http.get<IGetRunState>(`${this.resourceUrl}/${projectUid}/runState`, { observe: 'response' });
  }

  create(project: IPostProject): Observable<HttpResponse<IGetProject>> {
    return this.http.post<IGetProject>(this.resourceUrl, project, { observe: 'response' })
  }

  partialUpdate(projectUid: string, project: IPatchProject): Observable<HttpResponse<IGetProject>> {
    return this.http.patch<IGetProject>(`${this.resourceUrl}/${projectUid}`, project, { observe: 'response' })
  }

  addMember(projectUid: string, projectMember: IPostProjectMember): Observable<HttpResponse<IGetProjectMember>> {
    return this.http.post<IGetProjectMember>(`${this.resourceUrl}/${projectUid}/members`, projectMember, { observe: 'response' })
  }

  deleteMember(projectUid: string, projectMember: IPostProjectMember): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${projectUid}/members`, { body: projectMember, observe: 'response' });
  }

  deploy(projectUid: string): Observable<GetProjectDeploymentTask> {
    return this.http.post<IGetProjectDeploymentTask>(`${this.resourceUrl}/${projectUid}/deploy`, { observe: 'response' })
      .pipe(
        map((task: IGetProjectDeploymentTask) => new GetProjectDeploymentTask(task))
      );
  }

  destroy(projectUid: string): Observable<GetProjectDeploymentTask> {
    return this.http.post<IGetProjectDeploymentTask>(`${this.resourceUrl}/${projectUid}/destroy`, { observe: 'response' })
      .pipe(
        map((task: IGetProjectDeploymentTask) => new GetProjectDeploymentTask(task))
      );
  }

  archive(projectUid: string): Observable<GetProject> {
    return this.http.post<IGetProject>(`${this.resourceUrl}/${projectUid}/archive`, { observe: 'response' })
      .pipe(
        map((res: IGetProject) => new GetProject(res))
      );
  }

  delete(projectUid: string): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceUrl}/${projectUid}`, { observe: 'response' })
  }
}
