import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGetRunState } from 'src/app/models/GetRunState';
import { IGetRoomParticipant } from 'src/app/models/participants/GetRoomParticipant';
import { IPatchRoomParticipant } from 'src/app/models/participants/PatchRoomParticipant';
import { IPostRoomParticipant } from 'src/app/models/participants/PostRoomParticipant';
import { GetRoom, IGetRoom } from 'src/app/models/rooms/GetRoom';
import { GetRoomDeploymentTask, IGetRoomDeploymentTask } from 'src/app/models/rooms/GetRoomDeploymentTask';
import { IPatchRoom } from 'src/app/models/rooms/PatchRoom';
import { IPostRoom } from 'src/app/models/rooms/PostRoom';
import * as env from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class RoomService {
  protected resourceUrl = env.environment.baseApiUrl + '/room';

  constructor(protected http: HttpClient) { }

  create(room: IPostRoom): Observable<HttpResponse<IGetRoom>> {
    return this.http.post<IGetRoom>(this.resourceUrl, room, { observe: 'response' })
  }

  partialUpdate(roomUid: string, room: IPatchRoom): Observable<HttpResponse<IGetRoom>> {
    return this.http.patch<IGetRoom>(`${this.resourceUrl}/${roomUid}`, room, { observe: 'response' })
  }

  find(roomUid: string): Observable<GetRoom> {
    return this.http.get<IGetRoom>(`${this.resourceUrl}/${roomUid}`, { observe: 'response' })
      .pipe(
        map((res: HttpResponse<IGetRoom>) => new GetRoom(res.body!))
      );
  }

  getRunState(roomUid: string): Observable<HttpResponse<IGetRunState>> {
    return this.http.get<IGetRunState>(`${this.resourceUrl}/${roomUid}/runState`, { observe: 'response' });
  }

  query(): Observable<GetRoom[]> {
    return this.http.get<IGetRoom[]>(this.resourceUrl, { observe: 'response' })
      .pipe(
        map((res: HttpResponse<IGetRoom[]>) => res.body!.map(r => new GetRoom(r)))
      );
  }


  deploy(roomUid: string): Observable<GetRoomDeploymentTask> {
    return this.http.post<IGetRoomDeploymentTask>(`${this.resourceUrl}/${roomUid}/deploy`, { observe: 'response' })
      .pipe(
        map((task: IGetRoomDeploymentTask) => new GetRoomDeploymentTask(task))
      );
  }

  destroy(roomUid: string): Observable<GetRoomDeploymentTask> {
    return this.http.post<IGetRoomDeploymentTask>(`${this.resourceUrl}/${roomUid}/destroy`, { observe: 'response' })
      .pipe(
        map((task: IGetRoomDeploymentTask) => new GetRoomDeploymentTask(task))
      );
  }

  archive(roomUid: string): Observable<GetRoom> {
    return this.http.post<IGetRoom>(`${this.resourceUrl}/${roomUid}/archive`, { observe: 'response' })
      .pipe(
        map((res: IGetRoom) => new GetRoom(res))
      );
  }

  delete(roomUid: string): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceUrl}/${roomUid}`, { observe: 'response' })
  }


  addParticipant(roomUid: string, roomParticipant: IPostRoomParticipant): Observable<HttpResponse<IGetRoomParticipant>> {
    return this.http.post<IGetRoomParticipant>(`${this.resourceUrl}/${roomUid}/participants`, roomParticipant, { observe: 'response' })
  }

  deleteParticipant(roomUid: string, roomParticipant: IPostRoomParticipant): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${roomUid}/participants`, { body: roomParticipant, observe: 'response' });
  }

  updateRoomParticipant(roomUid: string, participant: IPatchRoomParticipant): Observable<HttpResponse<{}>> {
    return this.http.patch<IGetRoom>(`${this.resourceUrl}/${roomUid}/participants`, participant, { observe: 'response' })
  }

}
