import { CloudLabToastComponent } from './toast.component';
import { NgModule } from '@angular/core';
import { TecCoreModule } from '@tecan/ui/core';
import { TecIconModule } from '@tecan/ui/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CloudLabToastComponent
  ],
  imports: [
    TecCoreModule,
    TecIconModule,
    TranslateModule
  ],
  exports: [
    CloudLabToastComponent
  ],
})
export class CloudLabToastModule {}
