import { BannerComponent } from './banner/banner.component';
import { CloudLabUserCardModule } from '../shared/user-card/user-card.module';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './error/error.component';
import { FeatureFlagDirectiveModule } from 'src/app/core/directives/feature-directive.module';
import { FindLanguageFromKeyPipe } from '../shared/language/find-language-from-key.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TecIconModule } from '@tecan/ui/icon';

@NgModule({
  declarations: [
    BannerComponent,
    ErrorComponent,
    FindLanguageFromKeyPipe,
    HeaderComponent,
    SidebarComponent
  ],
  imports: [
    CloudLabUserCardModule,
    CommonModule,
    FeatureFlagDirectiveModule,
    FormsModule,
    ReactiveFormsModule, 
    RouterModule,
    SharedComponentsModule,
    TecIconModule
  ],
  exports: [
    BannerComponent,
    ErrorComponent,
    FindLanguageFromKeyPipe,
    HeaderComponent,
    SidebarComponent
  ]
})
export class LayoutComponentsModule { }