import { CloudLabOverlayComponent } from "./overlay.component";
import { ComponentRef, Injectable, TemplateRef, ViewContainerRef } from "@angular/core";
import { IOverlayButtonDef } from "./IOverlayButtonDef";
import { OverlayRef } from "./OverlayRef";

@Injectable({
  providedIn: 'root',
})
export class CloudLabOverlayService {

  private viewContainerRef!: ViewContainerRef;

  private overlayComponent?: ComponentRef<CloudLabOverlayComponent>;


  constructor(
  ) { }


  public setViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }


  public show(title: string, content: TemplateRef<any>, buttons: IOverlayButtonDef[], subline?: string): OverlayRef | undefined {
    if (!this.overlayComponent) {
      return this.createOverlay(title, content, buttons, subline);
    }
    else
    {
      return undefined;
    }
  }

  public close() {
    if (this.overlayComponent) {
      this.overlayComponent.destroy();
    }
  }


  private createOverlay(title: string, content: TemplateRef<any>, buttons: IOverlayButtonDef[], subline?: string): OverlayRef {
    this.overlayComponent = this.viewContainerRef.createComponent(CloudLabOverlayComponent);
    this.overlayComponent.instance.title = title;
    this.overlayComponent.instance.content = content;
    this.overlayComponent.instance.subline = subline;
    this.overlayComponent.instance.buttons = buttons;

    this.overlayComponent.onDestroy(() => {
      this.overlayComponent = undefined;
    });

    return new OverlayRef(this, this.overlayComponent);
  }
}