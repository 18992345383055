<!-- sidebar -->
<div *ngIf="indicatorStyle" class="cloudlab-card-indicator" [ngClass]="indicatorStyle"></div>

<!-- title -->
<div class="cloudlab-card-header">
  <h4 class="dark bold">{{ title }}</h4>
  <p *ngIf="subline" class="medium">{{ subline }}</p>
</div>

<!-- body -->
<div class="cloudlab-card-content">
  <ng-content></ng-content>
</div>