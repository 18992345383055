import { AuthService } from '@auth0/auth0-angular';
import { CloudLabOverlayService } from '../../shared/overlay/overlay.service';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { SignalRService } from '../../shared/signalr/service/signalr.service';
import { UserService } from '../../core/users/service/user.service';

@Component({
  selector: 'cloudlab-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  host: {
    class: 'cloudlab-main'
  },
})
export class MainComponent implements OnInit {

  public get showBanner(): boolean
  {
    return this.router.url === '/'
  }


  constructor(
    public auth: AuthService,
    private userService: UserService,
    private signalRService: SignalRService,
    private viewContainerRef: ViewContainerRef,
    private overlayDialogService: CloudLabOverlayService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.overlayDialogService.setViewContainerRef(this.viewContainerRef);

    this.auth.isAuthenticated$.subscribe(async (isAuthenticated) => {
      if (isAuthenticated) {
        this.signalRService.connect();
      }
    });

    this.auth.idTokenClaims$.subscribe(async (user) => {
      if (user?.__raw) {
        this.userService.identifyUser(user?.__raw)
        .subscribe(
          () => this.onSaveUserSuccess(),
          () => this.onSaveError()
        )
      }
    });
  }


  protected onSaveUserSuccess(): void {
    // this.previousState();
  }

  protected onSaveError(): void {
    // Api for inheritance.
  }

  protected onSaveFinalize(): void {
    //
  }
}
