<div>
  <div class="top">
    <ng-container *featureFlag="'rooms'">
      <a class="sidebar-link" routerLink="/room" routerLinkActive="active">
        <tec-icon title="Rooms" id="room-icon" key="../../../../assets/icons/TrainingRoom" [boxSize]=24 color="#272727">
        </tec-icon>
      </a>
    </ng-container>

    <ng-container *featureFlag="'projects'">
      <a class="sidebar-link" routerLink="/project" routerLinkActive="active">
        <tec-icon title="Projects" id="project-icon" key="../../../../assets/icons/Project" [boxSize]=24 color="#272727">
        </tec-icon>
      </a>
    </ng-container>

    <a class="sidebar-link" routerLink="/vmtemplate" routerLinkActive="active">
      <tec-icon title="Templates" id="template-icon" key="../../../../assets/icons/VM-Template" [boxSize]=24 color="#272727">
      </tec-icon>
    </a>
  </div>
</div>