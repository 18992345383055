import { User } from "@auth0/auth0-spa-js";
import { GlobalRole } from "src/app/enumerations/GlobalRole";
import { environment } from "src/environments/environment";

export function hasSomeRole(user: User, roles: GlobalRole[]): boolean {
    
    const roleMappings = environment.auth0.roleMappings;
    const rolesClaim = environment.auth0.rolesClaim;

    let claimedRoles = user[rolesClaim] as string;
    
    if (claimedRoles) {
        return claimedRoles
            .split(' ')
            .some(r => r in roleMappings && roles.indexOf(roleMappings[r]) !== -1);
    } else {
        return false;
    }
}