import { AuthGuard } from './core/guards/auth/auth.guard';
import { errorRoutes } from './components/layout/error/error.route';
import { FeatureGuard } from "./core/guards/feature/feature.guard";
import { headerRoute } from './components/layout/header/header.route';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { sidebarRoute } from './components/layout/sidebar/sidebar.route';

const layoutRoutes = [sidebarRoute, headerRoute];

const routes: Routes = [
  { 
    path: 'login',
    loadChildren: () => import('./components/core/login/login.module').then(m => m.CloudLabLoginModule) },
  {
    path: 'room',
    canActivate: [AuthGuard],
    canLoad: [FeatureGuard],
    data: { 
      pageTitle: 'cloudlab.room.title',
      feature: 'rooms'
    },
    loadChildren: () => import('./components/core/room/room.module').then(m => m.RoomModule),
  },
  {
    path: 'project',
    canActivate: [AuthGuard],
    canLoad: [FeatureGuard],
    data: { 
      pageTitle: 'cloudlab.project.title',
      feature: 'projects'
    },
    loadChildren: () => import('./components/core/project/project.module').then(m => m.ProjectModule),
  },
  {
    path: 'vmtemplate',
    canActivate: [AuthGuard],
    data: { 
      pageTitle: 'cloudlab.template.title',
    },
    loadChildren: () => import('./components/core/template/template.module').then(m => m.TemplateModule),
  },
  {
    path: 'report',
    canActivate: [AuthGuard],
    data: { 
      pageTitle: 'cloudlab.report.title',
    },
    loadChildren: () => import('./components/core/report/report.module').then(m => m.ReportModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    data: { pageTitle: 'cloudlab.guest.title' },
    loadChildren: () => import('./components/core/guest/guest.module').then(m => m.GuestModule),
  },
  ...layoutRoutes,
  ...errorRoutes,
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }