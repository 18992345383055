import { VmTemplateDraftOperation } from "src/app/enumerations/VmTemplateDraftOperation";
import { GetBackgroundTask, IGetBackgroundTask } from "../GetBackgroundTask";

export interface IGetVmTemplateVersionTask extends IGetBackgroundTask {
  vmTemplateUid: string;
  vmTemplateVersion: number;
  operation: VmTemplateDraftOperation;
}

export class GetVmTemplateVersionTask extends GetBackgroundTask implements IGetVmTemplateVersionTask {

  vmTemplateUid: string;
  vmTemplateVersion: number;
  operation: VmTemplateDraftOperation;


  constructor(simpleTask: IGetVmTemplateVersionTask) {
    super(simpleTask);
    this.vmTemplateUid     = simpleTask.vmTemplateUid;
    this.vmTemplateVersion = simpleTask.vmTemplateVersion;
    this.operation         = simpleTask.operation;
  }
}