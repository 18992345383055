import { FeatureConfig } from "../feature/feature.interface";

export interface IHealth {
    version: string;
    featureSets: FeatureConfig;
}

export class Health implements IHealth {
    constructor(
        public version: string,
        public featureSets: FeatureConfig,
    ) { }
}