import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { AuthService } from "@auth0/auth0-angular";
import * as signalR from '@microsoft/signalr';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from 'src/app/components/core/project/service/project.service';
import { RoomService } from 'src/app/components/core/room/service/room.service';
import { TemplateService } from 'src/app/components/core/template/service/template.service';
import { TaskStatus } from 'src/app/enumerations/TaskStatus';
import { VmInstanceOperation } from 'src/app/enumerations/VmInstanceOperation';
import { IGetProject } from 'src/app/models/projects/GetProject';
import { GetProjectDeploymentTask, IGetProjectDeploymentTask } from 'src/app/models/projects/GetProjectDeploymentTask';
import { IGetRoom } from 'src/app/models/rooms/GetRoom';
import { GetRoomDeploymentTask, IGetRoomDeploymentTask } from 'src/app/models/rooms/GetRoomDeploymentTask';
import { GetVmInstance, IGetVmInstance } from 'src/app/models/vm-instances/GetVmInstance';
import { GetVmInstanceTask, IGetVmInstanceTask } from 'src/app/models/vm-instances/GetVmInstanceTask';
import { IGetVmTemplateVersion } from 'src/app/models/vm-template-versions/GetVmTemplateVersion';
import { GetVmTemplateVersionTask, IGetVmTemplateVersionTask } from 'src/app/models/vm-template-versions/GetVmTemplateVersionTask';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class SignalRService {

  isConnected: boolean = false;
  hasFailed: boolean = true;

  connection!: signalR.HubConnection;

  roomDeploymentTaskReceived: EventEmitter<GetRoomDeploymentTask> = new EventEmitter<GetRoomDeploymentTask>();
  vmInstanceTaskReceived: EventEmitter<GetVmInstanceTask> = new EventEmitter<GetVmInstanceTask>();
  vmInstanceStateReceived: EventEmitter<GetVmInstance> = new EventEmitter<GetVmInstance>();
  vmTemplateVersionTaskReceived: EventEmitter<GetVmTemplateVersionTask> = new EventEmitter<GetVmTemplateVersionTask>();
  projectDeploymentTaskReceived: EventEmitter<GetProjectDeploymentTask> = new EventEmitter<GetProjectDeploymentTask>();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    public toasterService: ToastrService,
    private roomService: RoomService,
    private vmTemplateService: TemplateService,
    private projectService: ProjectService
  ) { }


  // Establish a connection to the SignalR server hub
  public connect(): void {
    this.authService.isAuthenticated$.subscribe(isAuthenticated => {
      if (isAuthenticated) {
        this.authService.getAccessTokenSilently({
          authorizationParams: {
            audience: environment.auth0.audience
          }
        }).subscribe(accessToken => this.connectPostAuthenticate(accessToken));
      }
    });
  }

  connectPostAuthenticate(accessToken: string): void {

    // create an authenticated SignalR connection
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(environment.signalrHubUrl, { accessTokenFactory: () => accessToken, transport: signalR.HttpTransportType.LongPolling + signalR.HttpTransportType.WebSockets })
      .withAutomaticReconnect()
      .build();

    // hook up reconnection
    this.connection.onreconnecting((error) => {
      console.error(`Lost connection to SignalR hub ${environment.signalrHubUrl}.`, error);
      this.toasterService.error('connection.live.reconnecting.message', 'connection.live.reconnecting.title');
    });

    this.connection.onreconnected((connectionId) => {
      console.log(`Reconnected to SignalR hub ${environment.signalrHubUrl} with connection id ${connectionId}.`);
      this.toasterService.success('connection.live.reconnected.message', 'connection.live.reconnected.title');
    });

    // hook up message listeners
    this.connection.on('UpdateRoomDeploymentTask', (taskString: string) => {
      let task: GetRoomDeploymentTask = new GetRoomDeploymentTask(<IGetRoomDeploymentTask>JSON.parse(taskString));
      this.showRoomDeploymentTaskToaster(task);
      this.roomDeploymentTaskReceived.emit(task);
    });

    this.connection.on('UpdateProjectDeploymentTask', (taskString: string) => {
      let task: GetProjectDeploymentTask = new GetProjectDeploymentTask(<IGetProjectDeploymentTask>JSON.parse(taskString));
      this.showProjectDeploymentTaskToaster(task);
      this.projectDeploymentTaskReceived.emit(task);
    });

    this.connection.on('UpdateVmInstanceTask', (taskString: string) => {
      let task: GetVmInstanceTask = new GetVmInstanceTask(<IGetVmInstanceTask>JSON.parse(taskString));
      this.showVmInstanceTaskToaster(task);
      this.vmInstanceTaskReceived.emit(task);
    });

    this.connection.on('UpdateVmInstanceState', (powerStateString: string) => {
      let powerState: GetVmInstance = new GetVmInstance(<IGetVmInstance>JSON.parse(powerStateString));
      this.vmInstanceStateReceived.emit(powerState);
    });

    this.connection.on('UpdateVmTemplateVersionTask', (taskString: string) => {
      let task: GetVmTemplateVersionTask = new GetVmTemplateVersionTask(<IGetVmTemplateVersionTask>JSON.parse(taskString));
      this.showVmTemplateVersionTaskToaster(task);
      this.vmTemplateVersionTaskReceived.emit(task);
    });

    // start the connection
    this.connection
      .start()
      .then(() => {
        console.log(`Connected to SignalR hub ${environment.signalrHubUrl} with connection id ${this.connection.connectionId}.`);
        this.isConnected = true;
      })
      .catch((error) => {
        console.error(`Could not connect to SignalR hub ${environment.signalrHubUrl}: ${error}`);
        this.hasFailed = true;
        
        console.info(`Attempting to reconnect to SignalR hub ${environment.signalrHubUrl}.`);
        this.connect();
      });
  }

  showRoomDeploymentTaskToaster(task: IGetRoomDeploymentTask): void {
    let title = 'room.operation.' + task.operation + '.' + task.status + '.title';
    let message = 'room.operation.' + task.operation + '.' + task.status + '.message';

    this.roomService.find(task.roomUid)
      .subscribe((res: IGetRoom) => {

        let options = { payload: { room: res } };

        if (task.status === TaskStatus.Queued) {
          this.toasterService.info(message, title, options);
        } else if (task.status === TaskStatus.InProgress) {
          this.toasterService.info(message, title, options);
        } else if (task.status === TaskStatus.Cancelled) {
          this.toasterService.info(message, title, options);
        } else if (task.status === TaskStatus.Error) {
          this.toasterService.error(message, title, options);
        } else if (task.status === TaskStatus.Success) {
          this.toasterService.success(message, title, options);
        }
      });
  }

  showProjectDeploymentTaskToaster(task: IGetProjectDeploymentTask): void {
    let title = 'project.operation.' + task.operation + '.' + task.status + '.title';
    let message = 'project.operation.' + task.operation + '.' + task.status + '.message';

    this.projectService.find(task.projectUid)
      .subscribe((res: IGetProject) => {

        let options = { payload: { project: res } };

        if (task.status === TaskStatus.Queued) {
          this.toasterService.info(message, title, options);
        } else if (task.status === TaskStatus.InProgress) {
          this.toasterService.info(message, title, options);
        } else if (task.status === TaskStatus.Cancelled) {
          this.toasterService.info(message, title, options);
        } else if (task.status === TaskStatus.Error) {
          this.toasterService.error(message, title, options);
        } else if (task.status === TaskStatus.Success) {
          this.toasterService.success(message, title, options);
        }
      });
  }

  showVmInstanceTaskToaster(task: IGetVmInstanceTask): void {

    if (task.operation !== VmInstanceOperation.GetPowerState) {

      let title = 'vmInstance.operation.' + task.operation + '.' + task.status + '.title';
      let message = 'vmInstance.operation.' + task.operation + '.' + task.status + '.message';
      let options = { payload: { vmInstance: { uid: task.vmInstanceUid } } };

      if (task.status === TaskStatus.Queued) {
        this.toasterService.info(message, title, options);
      } else if (task.status === TaskStatus.InProgress) {
        this.toasterService.info(message, title, options);
      } else if (task.status === TaskStatus.Cancelled) {
        this.toasterService.info(message, title, options);
      } else if (task.status === TaskStatus.Error) {
        this.toasterService.error(message, title, options);
      } else if (task.status === TaskStatus.Success) {
        this.toasterService.success(message, title, options);
      }
    }
  }

  showVmTemplateVersionTaskToaster(task: IGetVmTemplateVersionTask): void {
    let title = 'vmTemplateVersion.operation.' + task.operation + '.' + task.status + '.title';
    let message = 'vmTemplateVersion.operation.' + task.operation + '.' + task.status + '.message';

    this.vmTemplateService.getVmTemplateVersion(task.vmTemplateUid, task.vmTemplateVersion)
      .subscribe((res: IGetVmTemplateVersion) => {

        let options = { payload: { vmTemplateVersion: res } };

        if (task.status === TaskStatus.Queued) {
          this.toasterService.info(message, title, options);
        } else if (task.status === TaskStatus.InProgress) {
          this.toasterService.info(message, title, options);
        } else if (task.status === TaskStatus.Cancelled) {
          this.toasterService.info(message, title, options);
        } else if (task.status === TaskStatus.Error) {
          this.toasterService.error(message, title, options);
        } else if (task.status === TaskStatus.Success) {
          this.toasterService.success(message, title, options);
        }
      });
  }
}