<div>
  <div class="row">
    <div class="col-md-8">
      <h1 tecanTranslate="error.title">Error Page!</h1>

      <div *ngIf="errorMessage">
        <div class="alert alert-danger">{{ errorMessage }}</div>
      </div>
    </div>
  </div>
</div>