import { Component, ElementRef, HostBinding, Input, OnInit, TemplateRef } from "@angular/core";

@Component({
  selector: 'cloudlab-popover-container',
  templateUrl: './popover-container.component.html',
  styleUrls: ['./popover-container.component.scss']
})
export class PopoverContainerComponent {

  @Input() text!: string;

  @Input() content!: TemplateRef<any>;

  @Input() top: number = 0;
  
  @HostBinding('style.top')
  get topStyle() : string {
    return this.top + 'px';
  }

  @Input() left: number = 0;

  @HostBinding('style.left')
  get leftStyle() : string {
    return this.left + 'px';
  }
}