import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createRequestOption } from 'src/app/core/request-util';
import { IGetConnectionTemplate } from 'src/app/models/connection-templates/GetConnectionTemplate';
import { IPatchConnectionTemplate } from 'src/app/models/connection-templates/PatchConnectionTemplate';
import { IPostConnectionTemplate } from 'src/app/models/connection-templates/PostConnectionTemplate';
import { GetVmInstance, IGetVmInstance } from 'src/app/models/vm-instances/GetVmInstance';
import { GetVmInstanceTask, IGetVmInstanceTask } from 'src/app/models/vm-instances/GetVmInstanceTask';
import { GetVmTemplateVersion, IGetVmTemplateVersion } from 'src/app/models/vm-template-versions/GetVmTemplateVersion';
import { GetVmTemplateVersionTask, IGetVmTemplateVersionTask } from 'src/app/models/vm-template-versions/GetVmTemplateVersionTask';
import { IPatchVmTemplateVersion } from 'src/app/models/vm-template-versions/PatchVmTemplateVersion';
import { ICloneVmTemplateDto } from 'src/app/models/vm-templates/CloneVmTemplateDto';
import { GetVmTemplate, IGetVmTemplate } from 'src/app/models/vm-templates/GetVmTemplate';
import { IImportAzureImage } from 'src/app/models/vm-templates/ImportAzureImage';
import { IPatchVmTemplate } from 'src/app/models/vm-templates/PatchVmTemplate';
import { IGetVmTemplatePermissionAssignment } from 'src/app/models/vm-templates/permissions/GetVmTemplatePermissionAssignment';
import { IPostVmTemplatePermissionAssignment } from 'src/app/models/vm-templates/permissions/PostVmTemplatePermissionAssignment';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TemplateService {
  protected resourceUrl = environment.baseApiUrl + '/vmtemplate';

  constructor(protected http: HttpClient) { }

  import(template: IImportAzureImage): Observable<HttpResponse<IGetVmTemplate>> {
    return this.http.post<IGetVmTemplate>(`${this.resourceUrl}/import`, template, { observe: 'response' });
  }

  cloneTemplate(cloneVmTemplateDto?: ICloneVmTemplateDto): Observable<HttpResponse<IGetVmTemplateVersion>> {
    return this.http.post<IGetVmTemplateVersion>(`${this.resourceUrl}/clone`, cloneVmTemplateDto, { observe: 'response' });
  }

  partialUpdate(templateUid: string, template: IPatchVmTemplate): Observable<HttpResponse<IGetVmTemplate>> {
    return this.http.patch<IGetVmTemplate>(`${this.resourceUrl}/${templateUid}`, template, { observe: 'response' });
  }

  find(templateUid: string): Observable<HttpResponse<IGetVmTemplate>> {
    return this.http.get<IGetVmTemplate>(`${this.resourceUrl}/${templateUid}`, { observe: 'response' });
  }

  query(req?: any): Observable<HttpResponse<IGetVmTemplate[]>> {
    const options = createRequestOption(req);
    return this.http.get<IGetVmTemplate[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  delete(templateUid: string): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${templateUid}`, { observe: 'response' });
  }


  // PermissionAssignment

  addPermission(templateUid: string, permissionAssignment: IPostVmTemplatePermissionAssignment): Observable<HttpResponse<IGetVmTemplatePermissionAssignment>> {
    return this.http.post<IGetVmTemplatePermissionAssignment>(`${this.resourceUrl}/${templateUid}/permission`, permissionAssignment, { observe: 'response' })
  }

  deletePermission(templateUid: string, permissionAssignment: IPostVmTemplatePermissionAssignment): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${templateUid}/permission`, { body: permissionAssignment, observe: 'response' });
  }


  // VmTemplateVersion

  partialUpdateVersion(templateUid: string, templateVersion: number, template: IPatchVmTemplateVersion): Observable<HttpResponse<IGetVmTemplateVersion>> {
    return this.http.patch<IGetVmTemplateVersion>(`${this.resourceUrl}/${templateUid}/version/${templateVersion}`, template, { observe: 'response' });
  }

  getVmTemplateVersion(templateUid: string, templateVersion: number): Observable<GetVmTemplateVersion> {
    return this.http.get<IGetVmTemplateVersion>(`${this.resourceUrl}/${templateUid}/version/${templateVersion}`, { observe: 'response' })
      .pipe(
        map((res: HttpResponse<IGetVmTemplateVersion>) => new GetVmTemplateVersion(res.body!))
      );
  }

  retryImport(templateUid: string): Observable<GetVmTemplateVersionTask> {
    return this.http.post<IGetVmTemplateVersionTask>(`${this.resourceUrl}/${templateUid}/retryImport`, { observe: 'response' })
      .pipe(
        map((res: IGetVmTemplateVersionTask) => new GetVmTemplateVersionTask(res))
      );
  }

  cloneVersion(templateUid: string, templateVersion: number): Observable<IGetVmTemplateVersion> {
    return this.http.post<IGetVmTemplateVersion>(`${this.resourceUrl}/${templateUid}/version/${templateVersion}/clone`, { observe: 'response' })
      .pipe(
        map((res: IGetVmTemplateVersion) => new GetVmTemplateVersion(res))
      );
  }

  archiveVersion(templateUid: string, templateVersion: number): Observable<GetVmTemplateVersion> {
    return this.http.post<IGetVmTemplateVersion>(`${this.resourceUrl}/${templateUid}/version/${templateVersion}/archive`, { observe: 'response' })
      .pipe(
        map((res: IGetVmTemplateVersion) => new GetVmTemplateVersion(res))
      );
  }

  deleteVersion(templateUid: string, templateVersion: number): Observable<any> {
    return this.http.delete(`${this.resourceUrl}/${templateUid}/version/${templateVersion}`, { observe: 'response' })
      .pipe(
        map((res: HttpResponse<any>) => new GetVmTemplateVersionTask(res.body!))
      );
  }

  saveDraft(templateUid: string, templateVersion: number): Observable<GetVmTemplateVersionTask> {
    return this.http.post<IGetVmTemplateVersionTask>(`${this.resourceUrl}/${templateUid}/version/${templateVersion}/save`, { observe: 'response' })
      .pipe(
        map((res: IGetVmTemplateVersionTask) => new GetVmTemplateVersionTask(res))
      );
  }

  getDraftVm(templateUid: string, templateVersion: number): Observable<GetVmInstance | undefined> {
    return this.http.get<IGetVmInstance>(`${this.resourceUrl}/${templateUid}/version/${templateVersion}/draft/vminstance`, { observe: 'response' })
      .pipe(
        map((res: HttpResponse<IGetVmInstance>) => res.body ? new GetVmInstance(res.body!) : undefined)
      );
  }

  deployDraftVm(templateUid: string, templateVersion: number): Observable<GetVmInstanceTask> {
    return this.http.post<IGetVmInstanceTask>(`${this.resourceUrl}/${templateUid}/version/${templateVersion}/draft/deploy`, { observe: 'response' })
      .pipe(
        map((res: IGetVmInstanceTask) => new GetVmInstanceTask(res))
      );
  }

  destroyDraftVm(templateUid: string, templateVersion: number): Observable<GetVmInstanceTask> {
    return this.http.post<IGetVmInstanceTask>(`${this.resourceUrl}/${templateUid}/version/${templateVersion}/draft/destroy`, { observe: 'response' })
      .pipe(
        map((res: IGetVmInstanceTask) => new GetVmInstanceTask(res))
      );
  }


  // ConnectionTemplate

  createConnectionTemplate(templateUid: string, templateVersion: number, connectionTemplate: IPostConnectionTemplate): Observable<HttpResponse<IGetConnectionTemplate>> {
    return this.http.post<IGetConnectionTemplate>(`${this.resourceUrl}/${templateUid}/version/${templateVersion}/connections`, connectionTemplate, { observe: 'response' });
  }

  partialUpdateConnectionTemplate(templateUid: string, templateVersion: number, port: number, connectionTemplate: IPatchConnectionTemplate): Observable<HttpResponse<IGetConnectionTemplate>> {
    return this.http.patch<IGetConnectionTemplate>(`${this.resourceUrl}/${templateUid}/version/${templateVersion}/connections/${port}`, connectionTemplate, { observe: 'response' });
  }

  deleteConnectionTemplate(templateUid: string, templateVersion: number, port: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${templateUid}/version/${templateVersion}/connections/${port}`, { observe: 'response' });
  }
}

