<div *ngIf="pictureUrl" class="cloudlab-user-picture" [style.background-image]="'url(' + pictureUrl + ')'" [ngClass]="pictureSize">
</div>

<div class="cloudlab-user-info">
  <div class="cloudlab-user-name">
    {{ user.displayName }}
  </div>
  <div *ngIf="showEmail" class="cloudlab-user-email">
    {{ user.email }}
  </div>
</div>