import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from 'src/app/config/language.constants';

@Component({
  selector: 'cloudlab-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  host: {
    class: 'cloudlab-banner'
  },
})
export class BannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }
}
