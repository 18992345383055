import { Component, Input, TemplateRef } from '@angular/core';
import { IOverlayButtonDef } from './IOverlayButtonDef';

@Component({
  selector: 'cloudlab-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  host: {
    class: 'cloudlab-overlay'
  },
})
export class CloudLabOverlayComponent {

  @Input()
  title!: string;

  @Input()
  subline?: string;

  @Input()
  content!: TemplateRef<any>;

  @Input()
  buttons!: IOverlayButtonDef[];

}
