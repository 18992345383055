import { VmInstanceDeploymentState } from "src/app/enumerations/VmInstanceDeploymentState";
import { VmInstancePowerState } from "src/app/enumerations/VmInstancePowerState";
import { GetVmInstanceTask, IGetVmInstanceTask } from "./GetVmInstanceTask";

export interface IGetVmInstance {
  uid: string;
  powerState: VmInstancePowerState;
  deploymentState: VmInstanceDeploymentState;
  currentTask?: IGetVmInstanceTask;
}


export class GetVmInstance implements IGetVmInstance {

  uid: string;
  powerState: VmInstancePowerState;
  deploymentState: VmInstanceDeploymentState;
  currentTask?: GetVmInstanceTask;


  constructor(simpleVmInstance: IGetVmInstance) {
    this.uid             = simpleVmInstance.uid;
    this.powerState      = simpleVmInstance.powerState;
    this.deploymentState = simpleVmInstance.deploymentState;
    this.currentTask     = simpleVmInstance.currentTask ? new GetVmInstanceTask(simpleVmInstance.currentTask) : undefined;
  }


  public get hasActiveTask(): boolean {
    return this.currentTask?.isActive === true;
  }
}