import { CloudLabUserCardComponent } from './user-card.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { TecButtonModule } from '@tecan/ui/button';
import { TecCheckboxModule } from '@tecan/ui/checkbox';
import { TecInputModule } from '@tecan/ui/input';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CloudLabUserCardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,   
    TecButtonModule,
    TecCheckboxModule,
    TecInputModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [
    CloudLabUserCardComponent
  ],
})
export class CloudLabUserCardModule {}
