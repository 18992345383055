import { GetRoomParticipant, IGetRoomParticipant } from "../participants/GetRoomParticipant";
import { IGetVmTemplateVersion } from "../vm-template-versions/GetVmTemplateVersion";
import { DeploymentSchedule, IDeploymentSchedule } from "./DeploymentSchedule";
import { GetRoomDeploymentTask, IGetRoomDeploymentTask } from "./GetRoomDeploymentTask";
import { IGetBaseRoom } from "src/app/components/core/base-room/models/IGetBaseRoom";
import { RunState } from "src/app/components/core/base-room/enumerations/RunState";
import { DeploymentState } from "src/app/components/core/base-room/enumerations/DeploymentState";

export interface IGetRoom extends IGetBaseRoom{
  deploymentSchedule?: IDeploymentSchedule,
  currentTask?: IGetRoomDeploymentTask,
  requiresRedeployment: boolean,
  costCenter: string,
  location: string,
  vmTemplateVersion: IGetVmTemplateVersion,
  vmSize: string,
  participants: IGetRoomParticipant[]
}

export class GetRoom implements IGetRoom {
  uid!: string;
  title!: string;
  description?: string | undefined;
  deploymentSchedule?: IDeploymentSchedule;
  currentTask?: GetRoomDeploymentTask;
  deploymentState!: DeploymentState;
  runState = RunState.Pending;
  requiresRedeployment!: boolean;
  costCenter!: string;
  location!: string;
  vmTemplateVersion!: IGetVmTemplateVersion;
  vmSize!: string;
  participants!: GetRoomParticipant[];
  guacamoleFqdn?: string | undefined;


  constructor(simpleRoom: IGetRoom) {
    this.uid                  = simpleRoom.uid;
    this.title                = simpleRoom.title;
    this.description          = simpleRoom.description;
    this.deploymentSchedule   = simpleRoom.deploymentSchedule && simpleRoom.deploymentSchedule.start != null && simpleRoom.deploymentSchedule.end != null ? new DeploymentSchedule(new Date(simpleRoom.deploymentSchedule.start), new Date(simpleRoom.deploymentSchedule.end)) : undefined;
    this.currentTask          = simpleRoom.currentTask ? new GetRoomDeploymentTask(simpleRoom.currentTask) : undefined;
    this.deploymentState      = simpleRoom.deploymentState;
    this.requiresRedeployment = simpleRoom.requiresRedeployment;
    this.costCenter           = simpleRoom.costCenter;
    this.location             = simpleRoom.location;
    this.vmTemplateVersion    = simpleRoom.vmTemplateVersion;
    this.vmSize               = simpleRoom.vmSize;
    this.participants         = simpleRoom.participants.map(p => new GetRoomParticipant(p));
    this.guacamoleFqdn        = simpleRoom.guacamoleFqdn;
  }


  public get hasActiveTask(): boolean {
    return this.currentTask?.isActive === true;
  }
}