<!-- header -->
<cloudlab-header></cloudlab-header>

<!-- body -->
<div class="cloudlab-body-container">

    <!-- sidebar -->
    <cloudlab-sidebar *cloudlabHasRole="['Admin','ProjectAdmin','RoomAdmin','TemplateAdmin','Host','ProjectOwner']"></cloudlab-sidebar>

    <!-- content -->
    <div class="cloudlab-content-container">
        
        <cloudlab-banner *ngIf="showBanner"></cloudlab-banner>
        
        <div class="cloudlab-content">
            <router-outlet></router-outlet>
        </div>
    </div> 
</div>