import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from 'src/app/config/language.constants';

@Component({
  selector: 'cloudlab-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  host: {
    class: 'cloudlab-sidebar'
  },
})
export class SidebarComponent implements OnInit {
  languages = LANGUAGES;
  usedLanguage: string = 'en';

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private translateService: TranslateService
    ) { }

  ngOnInit(): void {

  }

  changeLanguage(): void {
    let currentLang = this.translateService.currentLang;
    switch (currentLang) {
      case 'en':
        this.translateService.use('de');
        this.usedLanguage = 'de'
        break;
      case 'de':
        this.translateService.use('en');
        this.usedLanguage = 'en'
        break;
      default: 'en'
        this.translateService.use('en');
        this.usedLanguage = 'en'
        break;
    }
  }

}
