import { AuthService, User } from '@auth0/auth0-angular';
import { Component, OnInit } from '@angular/core';
import { IGetUser } from 'src/app/models/users/GetUser';
import { Router } from '@angular/router';

@Component({
  selector: 'cloudlab-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    class: 'cloudlab-header'
  },
})
export class HeaderComponent implements OnInit {

  public user!: IGetUser;


  constructor(
    public auth: AuthService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.auth.user$.subscribe(user => {
      if (user) {
        this.user = {
          id: user.sub!,
          issuer: '',
          displayName: user.name!,
          email: user.email!,
          picture: user.picture,
          roles: []
        };
      }
    });
  }

  logout(): void {
    const loginUrl = window.location.origin + this.router.createUrlTree(
      ['/login'],
      { queryParams: 
        { 
          reason: 'logout'
        }
      });
    console.warn("Logging out...")
    this.auth.logout({ logoutParams: { returnTo: loginUrl }});
  }

}
