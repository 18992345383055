import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'duration',
    pure: true
})
export class DurationPipe implements PipeTransform {

    readonly steps = [
        {
            divisor: 1,
            symbol: 's'
        },
        {
            divisor: 60,
            symbol: 'min'
        },
        {
            divisor: 3600,
            symbol: 's'
        },
        {
            divisor: 86400,
            symbol: 'd'
        }
    ];


    transform(value: any, args?: any): any {

        let seconds = value as number;
        let i = 0;
        for (; i < this.steps.length && this.steps[i].divisor < seconds; ++i);

        let rounded = Math.floor(seconds / this.steps[i].divisor);
        return `${rounded}${this.steps[i].symbol}`;
    }

}