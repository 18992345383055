import { RoomRole } from "src/app/enumerations/RoomRole";
import { IGetUser } from "../users/GetUser";
import { GetVmInstance, IGetVmInstance } from "../vm-instances/GetVmInstance";

export interface IGetRoomParticipant {
    user: IGetUser;
    role: RoomRole;
    vmInstance?: IGetVmInstance;
}

export class GetRoomParticipant implements IGetRoomParticipant {
    user: IGetUser;
    role: RoomRole;
    vmInstance?: GetVmInstance;


    constructor(simpleParticipant: IGetRoomParticipant) {
        this.user       = simpleParticipant.user;
        this.role       = simpleParticipant.role;
        this.vmInstance = simpleParticipant.vmInstance ? new GetVmInstance(simpleParticipant.vmInstance) : undefined;
    }
}