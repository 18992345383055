import { Component, Input } from "@angular/core";
import { IndicatorStyle } from "src/app/enumerations/IndicatorStyle";

@Component({
  selector: 'cloudlab-card',
  host: {
    class: 'cloudlab-card'
  },
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CloudLabCardComponent {

  @Input() title!: string;

  @Input() subline?: string;

  @Input() indicatorStyle?: IndicatorStyle;

  get indicatorStyleClass() : string | undefined {
    return this.indicatorStyle;
  }

}