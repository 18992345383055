import { Component, Input, OnInit } from "@angular/core";
import { IGetUser } from "src/app/models/users/GetUser";
import { UserPictureSize } from "./UserPictureSize";

@Component({
  selector: 'cloudlab-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: [ './user-card.component.scss' ],
  host: {
    class: 'cloudlab-user-card'
  },
})
export class CloudLabUserCardComponent implements OnInit {

  @Input()
  user!: IGetUser;

  @Input()
  showEmail: boolean = true;

  @Input()
  pictureSize: UserPictureSize = UserPictureSize.small;


  public pictureUrl!: string;


  public ngOnInit(): void {

    if (this.user.picture)
      this.pictureUrl = this.user.picture;

    // use random avatars as fallback
    else {
      let split = this.user.displayName.split(' ');
      let initials = split.map(s => s.charAt(0)).join();
      this.pictureUrl = 'https://ui-avatars.com/api/?background=random&size=480&font-size=0.4&color=FFFFFF&name=' + initials;
    }
  }
}