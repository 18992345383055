import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGetUser } from 'src/app/models/users/GetUser';
import { IPatchUser } from 'src/app/models/users/PatchUser';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
  protected resourceUrl = environment.baseApiUrl + '/user';

  constructor(protected http: HttpClient) { }

  partialUpdate(userId: number, user: IPatchUser): Observable<HttpResponse<IGetUser>> {
    return this.http.patch<IGetUser>(`${this.resourceUrl}/${userId}`, user, { observe: 'response' });
  }

  query(queryString?: any): Observable<HttpResponse<IGetUser[]>> {
    let params = queryString ? { query: queryString } : undefined;
    return this.http.get<IGetUser[]>(`${this.resourceUrl}/query`, { observe: 'response', params: params });
  }

  identifyUser(idToken: string): Observable<IGetUser> {
    return this.http.post<IGetUser>(`${this.resourceUrl}/identify?identityToken=${idToken}`, { observe: 'response' });
  }
}
