import { DeploymentOperation } from "src/app/enumerations/DeploymentOperation";
import { GetBackgroundTask, IGetBackgroundTask } from "../GetBackgroundTask";

export interface IGetProjectDeploymentTask extends IGetBackgroundTask {
  projectUid: string;
  operation: DeploymentOperation;
}

export class GetProjectDeploymentTask extends GetBackgroundTask implements IGetProjectDeploymentTask {
  
  projectUid: string;
  operation: DeploymentOperation;


  constructor(simpleTask: IGetProjectDeploymentTask) {
    super(simpleTask);
    this.projectUid   = simpleTask.projectUid;
    this.operation = simpleTask.operation;
  }
}